import { getEuroFormatFromEuro, getPercentFormat } from '@/utils/Money'

export default (invoiceData) => {
    let services = invoiceData.services
    let existingServiceIndex
    if (invoiceData.mergeLines) {
        services = []
        invoiceData.services.forEach((invoiceService) => {
            existingServiceIndex = services.findIndex(service => service.PU === invoiceService.PU)
            if (existingServiceIndex !== -1) {
                services.splice(existingServiceIndex, 1, {
                    ...services[existingServiceIndex],
                    qty: (Math.round(services[existingServiceIndex].qty * 100) + Math.round(invoiceService.qty * 100)) / 100,
                    tot: (Math.round(services[existingServiceIndex].tot * 100) + Math.round(invoiceService.tot * 100)) / 100,
                })
            } else {
                services.push(invoiceService)
            }
        })
    }
    // console.log('services', services)
    let servicesArray = services.map((service, index) => {
        return [
            { text: index + 1, style: 'p1'},
            { text: service.label, style: 'p1'},
            { text: service.qty, style: 'p1', alignment: 'center'},
            { text: getEuroFormatFromEuro(service.PU, false), style: 'p1', alignment: 'right'},
            { text: getEuroFormatFromEuro(service.tot, false), style: 'p1', alignment: 'right'},
        ]
    })
    const header = () => {
        return {
            image: invoiceData.companyLogo ?? 'logo',
            width: 50,
            margin: [ 10, 10, 0, 0 ]
        }
    }

    const footer = () => {
        return {
            text: invoiceData.footerTemplate,
            style: "footer"
        }
    }
    // watermark: { text: 'Page 1', color: 'blue', opacity: 0.3, bold: true, italics: false },
    // { colSpan: 2, rowSpan: 2, text: 'Both:\nrowSpan and colSpan\ncan be defined at the same time' }
    // [
    //     { text: 'Libellé :', style: 'p1'},
    //     { text: '', style: 'p1'},
    // ],
    // [
    //     { text: '31130 Balma Cedex', style: 'p1', border: [false, false, false, false]},
    // ],
    var docDefinition = {
        pageMargins: [35, 43, 35, 60],
        content: [
            {
                columns: [
                    {
                        margin: [0, 50, 0],
                        width: '*',
                        table: {
                            widths: ['*'],
                            body: [
                                [
                                    { text: (invoiceData.invoiceAddress || invoiceData.invoiceAddress?.length > 0) ? 'Adresse de facturation :' : '', style: 'p2', border: [false, false, false, false], decoration: 'underline'},
                                ],
                                [
                                    { text: `${invoiceData.invoiceAddress ?? ''}`, style: 'p1', border: [false, false, false, false]},
                                ]
                            ]
                        },
                        border: [false,false,false,false]
                    },
                    {
                        margin: [0, 30, 0, 0],
                        width: '*',
                        table: {
                            widths: ['*'],
                            body: [
                                [
                                    { text: 'Client :', style: 'p2', border: [false, false, false, false], decoration: 'underline'},
                                ],
                                [
                                    { text: invoiceData.client.name, style: 'p2', border: [false, false, false, false]},
                                ],
                                [
                                    { text: `${invoiceData.client.address ?? ''}`, style: 'p1', border: [false, false, false, false]},
                                ],
                                [
                                    { text: `${invoiceData.client.postalCode ?? ''} ${invoiceData.client.city ?? ''} ${invoiceData.client.country ?? ''}`, style: 'p1', border: [false, false, false, false]},
                                ],
                                [
                                    { text: invoiceData.client.foreign ? `Numéro identification : ${invoiceData.client.identificationNumber ?? ''}` : `SIREN : ${invoiceData.client.siret?.substr(0, 9)}`, style: 'p1', border: [false, false, false, false]},
                                ],
                                [
                                    { text: 'N° TVA Intracommunautaire : ' + (invoiceData.tva_i ?? ''), style: 'p1', border: [false, false, false, false]},
                                ],
                            ]
                        },
                        border: [false,false,false,false]
                    }
                ]
            },
            ' ',
            {
                margin: [0, 40, 0, 0],
                table: {
                    widths: [130, '*'],
                    body: [
                        [
                            { text: 'Date :', style: 'p1'},
                            { text: invoiceData.dateI.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' }), style: 'p1'},
                        ],
                        [
                            { text: 'Echéance :', style: 'p1', margin: [0, 5, 0, 0]},
                            { text: `${invoiceData.settlementPeriodDelay} \n${invoiceData.settlementPeriod.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long' })}`, style: 'p1'},
                        ],
                        [
                            { text: 'N° de facture :', style: 'p1'},
                            { text: invoiceData.invoiceNumberTemplated, style: 'p1'},
                        ],
                        [
                            { text: 'Intervention de :', style: 'p1'},
                            { text: invoiceData.IndepFullName, style: 'p1'},
                        ],
                        [
                            { text: 'Référence client :', style: 'p1'},
                            { text: invoiceData.refCli, style: 'p1'},
                        ],
                    ]
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        if (columnIndex === 0) {
                            return '#D9D9D9';
                        }
                        return '#FFFFFF';
                    },
                    hLineColor: function () {
                        return '#FFFFFF';
                    },
                    vLineColor: function () {
                        return '#FFFFFF';
                    },
                },
            },
            ' ',
            {
                table: {
                    widths: [20, '*', 30, 100, 155],
                    body: [
                        [
                            { text: 'N°', style: 'p2'},
                            { text: 'Désignation', style: 'p2'},
                            { text: 'Qté', style: 'p2', alignment: 'center'},
                            { text: 'Prix Unit. HT', style: 'p2', alignment: 'right'},
                            { text: 'Montant HT', style: 'p2', alignment: 'right'},
                        ],
                        ...servicesArray,
                        [
                            { text: '', style: 'p1', colSpan: 5, border: [false, false, false, false], margin: [0, 40, 0, 0]},
                            '',
                            '',
                            '',
                            ''
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'Total HT', style: 'p1', alignment: 'center'},
                            { text: getEuroFormatFromEuro(invoiceData.totalHT, false), style: 'p1', alignment: 'right'},
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: `TVA à ${getPercentFormat(invoiceData.tauxTVA)}`, style: 'p1', alignment: 'center'},
                            { text: getEuroFormatFromEuro(invoiceData.TVA, false), style: 'p1', alignment: 'right'},
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'Montant total TTC', style: 'p1', alignment: 'center'},
                            { text: getEuroFormatFromEuro(invoiceData.totalTTC, false), style: 'p1', alignment: 'right'},
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 5, border: [false, false, false, false], margin: [0, 10, 0, 0]},
                            '',
                            '',
                            '',
                            ''
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'Devise', style: 'p1', alignment: 'center'},
                            { text: invoiceData.currency, style: 'p1', alignment: 'right'},
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'Type de TVA', style: 'p1', alignment: 'center'},
                            { text: invoiceData.typeTva, style: 'p1', alignment: 'right'},
                        ],
                        [ // todo: need to change later on
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'N° TVA Intracommunautaire', style: 'p1', alignment: 'center'},
                            { text: 'FR40824544597', style: 'p1', alignment: 'right'},
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'Règlement', style: 'p1', alignment: 'center'},
                            { text: invoiceData.reglement, style: 'p1', alignment: 'right'},
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'Banque', style: 'p1', alignment: 'center'},
                            { text: invoiceData.bankAccount, style: 'p1', alignment: 'right'},
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'IBAN', style: 'p1', alignment: 'center'},
                            { text: invoiceData.iban, style: 'p1', alignment: 'right'},
                        ],
                        [
                            { text: '', style: 'p1', colSpan: 3, border: [false, false, false, false]},
                            '',
                            '',
                            { text: 'BIC', style: 'p1', alignment: 'center'},
                            { text: invoiceData.bic, style: 'p1', alignment: 'right'},
                        ],
                    ]
                },
                layout: {
                    fillColor: function (rowIndex) {
                        if (rowIndex === 0) {
                            return '#BAD8EE';
                        }
                        return '#FFFFFF';
                    },
                    hLineColor: function () {
                        return '#95B3D7';
                    },
                    vLineColor: function () {
                        return '#95B3D7';
                    },
                },
            },
            { text: invoiceData.vatLiquidation ? '« auto-liquidation par le preneur art 283-2 du CGI » ou « Art 44 de la directive 2008/8 ».' : '', style: 'p1', margin: [0, 20, 0, 0]},
            { text: invoiceData.invoicePaymentsRequirements ?? '', style: 'paymentRequirements', margin: [0, 40, 0, 0], color: '#7F7F7F' }
        ],
        styles: {
            p1: {
                fontSize: 8,
                bold: false,
            },
            p2: {
                fontSize: 8,
                bold: true,
            },
            footer: {
                fontSize: 9,
                alignment: 'center',
                margin: [0, 0, 0, 50]
            },
            paymentRequirements: {
                fontSize: 9
            }
        },
        header,
        footer
    }
    return docDefinition
}
